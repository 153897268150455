import { createStore } from '@frontend/store';

type NotificationsStore = {
  mode: 'default' | 'experimental';
  setMode: (mode: 'default' | 'experimental') => void;
};

export const useNotificationsStore = createStore<NotificationsStore>(
  (set) => ({
    mode: 'default',
    setMode: (mode) => set({ mode }),
  }),
  {
    name: 'notifications-store',
  }
);

if (window.shell) {
  window.shell.on('notification:mode', (_, mode) => {
    useNotificationsStore.getState().setMode(mode);
  });
}
