import env from '@frontend/env';
import { IpcShellProvider } from '@frontend/shell-utils';
import { ThemeProvider } from '@frontend/design-system';
import { App } from './app';
import { ErrorBoundary } from './components/error-boundary';
import { DebugModeProvider } from './providers/debug-provider';
import { NotificationManagerProvider } from './providers/notification-manager';

const Root = () => {
  console.log('Version: - ', env.VERSION);
  return (
    <IpcShellProvider context='notifications'>
      <ThemeProvider includeEmotionTheme skipAnimation={false}>
        <ErrorBoundary>
          <DebugModeProvider>
            <NotificationManagerProvider>
              <App />
            </NotificationManagerProvider>
          </DebugModeProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </IpcShellProvider>
  );
};

export default Root;
